import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { updateAccount } from "@/api/account"; // 导入修改账户信息的接口

export default defineComponent({
  name: "editAccount",

  setup() {
    const form = ref({
      appLoginId: "",
      appLoginName: "",
      appLoginPwd: "",
      loginName: "",
      id: 0 // 账户ID

    });
    const router = useRouter();
    const route = useRoute(); // 用于获取传入的参数

    const errors = ref({
      appLoginId: "",
      appLoginName: "",
      appLoginPwd: "",
      loginName: ""
    });
    const appCode = ref(""); // 保存传入的 appCode
    // 初始化时获取传入的参数，并填充表单数据

    onMounted(() => {
      const accountInfo = route.query; // 假设你传入的是账户信息

      form.value.appLoginId = accountInfo.appLoginId;
      form.value.appLoginName = accountInfo.appLoginName;
      form.value.appLoginPwd = accountInfo.appLoginPwd;
      form.value.loginName = accountInfo.loginName;
      form.value.id = accountInfo.id;
      appCode.value = accountInfo.appCode; // 从路由参数中获取 appCode
    });

    const onClickLeft = () => {
      router.back(); // 返回上一页
    }; // 验证表单


    const validateForm = () => {
      errors.value.appLoginId = form.value.appLoginId ? "" : "Login ID is required";
      errors.value.appLoginName = form.value.appLoginName ? "" : "Login name is required";
      errors.value.appLoginPwd = form.value.appLoginPwd ? form.value.appLoginPwd.length >= 6 ? "" : "Password must be at least 6 characters" : "Password is required";
      errors.value.loginName = form.value.loginName ? "" : "Phone (Login Name) is required";
      return !errors.value.appLoginId && !errors.value.appLoginName && !errors.value.appLoginPwd && !errors.value.loginName;
    }; // 提交表单


    const onSubmit = async () => {
      if (validateForm()) {
        try {
          const response = await updateAccount({
            appCode: appCode.value,
            appLoginId: form.value.appLoginId,
            appLoginName: form.value.appLoginName,
            appLoginPwd: form.value.appLoginPwd,
            loginName: form.value.loginName,
            id: form.value.id // 账户ID

          });

          if (response.code == 1) {
            _Toast.success("Account updated successfully!"); // 修改成功提示


            setTimeout(() => {
              router.push("/accountList"); // 修改成功后跳转到账户列表页面
            }, 2000);
          } else {
            _Toast.fail(response.msg || "Unknown error"); // 创建失败提示

          }
        } catch (error) {
          console.error("修改账户失败", error);
          errors.value.loginName = "Network error, please try again";
        }
      } else {
        console.log("Validation failed, please check the form.");
      }
    };

    return {
      form,
      errors,
      onSubmit,
      onClickLeft
    };
  }

});