import { get, post } from "@/utils/request";
// 添加账户接口
export function saveAccount(params) {
    return post("/app/user/save", params);
}
// app分页查询
export function pageapp() {
    return get("/app/user/pageapp");
}
// 用户账户列表
export function accountPage(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/page?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 修改账户信息接口
export function updateAccount(data) {
    return post("/app/user/update", data);
}
// 分页查询UPI账户信息接口
export function pageUpi(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    const url = `/app/user/pageupi?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 添加upi接口
export function saveupi(params) {
    return post("/app/user/saveupi", params);
}
